import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import {
  Box,
  Heading,
  Container
} from '@chakra-ui/react'
import NotFoundCompassLottie from "../components/NotFoundCompassLottie/NotFoundCompassLottie"


// markup
const NotFoundPage = () => {
  return (
    <Layout>
    <Seo title="404 Not Found" />
    
    <Container maxW="container.xl">
    <Box mt="8" mb="12">
        <Heading as="h1" variant="h1" mt="12" textAlign="center" color="sage.700">
          This page cannot be found.
        </Heading>
        <NotFoundCompassLottie />
          <Box textAlign="center">
            Back to <Link to="/">Homepage</Link>
          </Box>
        </Box>
    </Container>
    </Layout>
  )
}

export default NotFoundPage
