import React, { useEffect, useRef, useState } from "react";
import { useLottie } from "lottie-react";
import {
  Box,
  Center,
  Text
} from '@chakra-ui/react'
import CompassLottie from "./compass-lottie.json";

export default function ThankYouLottie() {
  const options = {
    animationData: CompassLottie,
    loop: true,
  }

  const { View } = useLottie(options);

  return (
    <Center>
        <Box w={{base: "75%", md: "20%"}}>
          <Box>{View}</Box>
        </Box>
    </Center>
  )
}
